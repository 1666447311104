import config from "config/config";
import User from "redux/models/User.model";
import { getJwtExpiration } from "utils/jwt-decode";

const initialState = {
  users: [],
  usersUpToDate: false,
  isLoggedIn: false,
  user: new User(),
  roles: [],
  errorMessage: "",
  userRetrieved: false,
  status: "",
  loginErrorRedirect: "",
  isPasswordResetTokenValid: false,
  loading: false,
  shouldRedirectToLogin: false,
  added: false,
  updated: false,
  deleted: false,
  error: "",
  idleWarning: false,
  isSessionExpired: false,
  passwordResetSucceeded: false,
  passwordResetFailed: false,
  emailAlert: false,
  lockedUsers: [],
  loginToken: null,
  refreshToken: null,
  idleTimeout: null,
  reminderTime: null,
  expiredError: null,
};
if (window.sessionStorage.getItem("user")) {
  try {
    initialState.user = new User(
      JSON.parse(window.sessionStorage.getItem("user")),
    );
    initialState.isLoggedIn = true;
  } catch (error) {}
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case "LOGOUT":
      window.sessionStorage.clear();
      window.location = "";
      return initialState;
    case "RESET_ALERTS":
      return {
        ...state,
        added: false,
        deleted: false,
        updated: false,
        emailAlert: false,
        profileUpdated: false,
        error: "",
      };
    case "RESET_LOGIN_ERROR":
      return {
        ...state,
        loginError: "",
      };

    case "GET_USERS":
      return {
        ...state,
        fetchingUsers: true,
        usersUpToDate: true,
        userRetrieved: false,
      };
    case "GET_ROLES":
      return {
        ...state,
        gettingRoles: true,
      };
    case "GET_ROLES_SUCCESS":
      return {
        ...state,
        roles: action.data,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        users: action.data.results,
        fetchingUsers: false,
        userRetrieved: true,
        usersUpToDate: true,
        next: action.data.next,
        previous: action.data.previous,
        totalRows: action.data.count,
      };
    case "GET_USERS_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "ADD_USER":
      return {
        ...state,
        added: false,
      };
    case "ADD_USER_SUCCESS":
      return {
        ...state,
        users: state.users.concat([new User(action.data)]),
        added: true,
        emailAlert: false,
        updated: false,
        deleted: false,
        usersUpToDate: false,
      };
    case "ADD_USER_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "DELETE_USER":
      return {
        ...state,
        deleted: false,
      };
    case "DELETE_USER_SUCCESS":
      return {
        ...state,
        users: deleteUser(action.data, state.users),
        deleted: true,
        emailAlert: false,
        added: false,
        updated: false,
        usersUpToDate: false,
      };
    case "DELETE_USER_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "UPDATE_USER":
      return {
        ...state,
        updated: false,
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        users: updateUser(action.data, state.users),
        updated: true,
        emailAlert: false,
        added: false,
        deleted: false,
        usersUpToDate: false,
      };
    case "UPDATE_USER_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "UPDATE_PROFILE":
      return {
        ...state,
        profileUpdated: false,
        error: "",
      };
    case "UPDATE_PROFILE_SUCCESS":
      window.sessionStorage.setItem("user", JSON.stringify(action.data));
      return {
        ...state,
        user: action.data && new User(action.data),
        profileUpdated: true,
      };
    case "UPDATE_PROFILE_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "LOGIN":
      return {
        ...state,
        loggingIn: true,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        loginError:
          (action.error && action.error.message) || "Invalid credentials",
        isLoggedIn: false,
        loggingIn: false,
        loginErrorRedirect: null,
      };
    case "LOGIN_SUCCESS":
      const { token } = action.data;
      config.setAuthToken(action.data);
      config.setOnOffFeature(action.data);
      return {
        ...state,
        isLoggedIn: !!action.data.user,
        user: action.data.user && new User(action.data.user),
        loginError: action.data.error,
        loginErrorRedirect: action.data.redirect,
        loggingIn: false,
        loginToken: token,
        refreshToken: action.data.refresh,
        expiration: jwtDecodeGetExp(token),
      };
    case "GET_REFRESH_TOKEN_SUCCESS":
      const { access, refresh } = action.data;
      const accessToken = "Bearer " + access;
      access && window.sessionStorage.setItem("token", accessToken);
      refresh && window.sessionStorage.setItem("refresh", refresh);
      return {
        ...state,
        refreshToken: refresh,
        loginToken: access,
        expiration: jwtDecodeGetExp(access),
      };
    case "GET_REFRESH_TOKEN_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "ASSIGN_IDLE_WARNING":
      return {
        ...state,
        idleWarning: true,
      };
    case "RESET_IDLE_WARNING":
      return {
        ...state,
        idleWarning: false,
      };
    case "ASSIGN_SESSION_EXPIRED_WARNING":
      return {
        ...state,
        isSessionExpired: true,
      };
    case "RESET_SESSION_EXPIRED_WARNING":
      return {
        ...state,
        isSessionExpired: false,
      };
    case "ASSIGN_ERROR_RESPONSE_WARNING":
      const isLoginEndpoint = action?.data.url.includes("/api/login/");
      const isShipmentsEndpoint = action?.data.url.includes(
        "/api/shipment/shipments/",
      );

      let redirectToRecover = null;
      let recoverError = null;

      let errorOccurred = true;
      if (isShipmentsEndpoint) {
        errorOccurred = false;
      }

      if (isLoginEndpoint && action.data?.status == 403) {
        recoverError = "Your password has expired.";
        redirectToRecover = "/recover";
      }
      return {
        ...state,
        hasErrorOccurred: errorOccurred,
        loginErrorRedirect: redirectToRecover,
        expiredError: recoverError,
      };
    case "RESET_ERROR_RESPONSE_WARNING":
      return {
        ...state,
        hasErrorOccurred: false,
        reminderTime: false,
      };
    case "FORGOT_PASSWORD":
      return {
        ...state,
        loading: true,
      };
    case "FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        status: action.data.data,
        errorMessage: "",
        emailAlert: true,
        updated: false,
        added: false,
        deleted: false,
      };
    case "FORGOT_PASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        status: "",
        errorMessage: action.error.error,
      };
    case "VALIDATE_PASSWORD_TOKEN":
      return {
        ...state,
        loading: true,
        isPasswordResetTokenValid: false,
      };
    case "VALIDATE_PASSWORD_TOKEN_SUCCESS":
      return {
        ...state,
        status: action.data,
        loading: false,
        isPasswordResetTokenValid: true,
      };
    case "VALIDATE_PASSWORD_TOKEN_FAILED":
      return {
        ...state,
        loading: false,
        isPasswordResetTokenValid: false,
      };
    case "CHANGE_PASSWORD":
      return {
        ...state,
        loading: true,
        passwordResetFailed: false,
        passwordResetSucceeded: false,
      };
    case "CHANGE_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        passwordResetSucceeded: true,
        loginErrorRedirect: "",
      };
    case "CHANGE_PASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        passwordResetFailed: true,
        loginErrorRedirect: "",
        error: action.data,
      };
    case "RESET_UPTODATE_FLAG":
      return {
        ...state,
        usersUpToDate: false,
      };
    case "GET_LOCKED_USERS":
      return {
        ...state,
        lockedUsersUpToDate: true,
        fetchingLockedUsers: true,
      };
    case "GET_LOCKED_USERS_SUCCESS":
      return {
        ...state,
        lockedUsers: action.data,
        lockedUsersUpToDate: true,
        lockedUsersRetrieved: true,
        fetchingLockedUsers: false,
      };
    case "GET_LOCKED_USERS_Failed":
      return {
        ...state,
        lockedUsersUpToDate: false,
        fetchingLockedUsers: false,
      };
    case "UNLOCK_USER_SUCCESS":
      return {
        ...state,
        lockedUsersUpToDate: false,
      };
    case "UNLOCK_USER_FAILED":
      return {
        ...state,
        lockedUsersUpToDate: false,
      };
    case "REMINDER_TIME_WARNING":
      return {
        ...state,
        reminderTime: true,
        idleTimeout: action.time,
      };
    case "RECOVER_ERROR_MESSAGE":
      return {
        ...state,
        expiredError: null,
        status: "",
      };

    default:
      return state;
  }
};

const deleteUser = (id, users) => users.filter((u) => u.id !== id);

const updateUser = (user, users) =>
  users.map((u) => (u = u.id === user.id ? user : u));

const jwtDecodeGetExp = (token) => {
  try {
    const exp = getJwtExpiration(token);
    window.sessionStorage.setItem("expiration", exp);
    return exp;
  } catch (error) {
    console.error("Error getting token decoded:", error?.message);
    return null;
  }
};
