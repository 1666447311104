import React from "react";

export default {
  home: (
    <>
      <i className="icon-home4" />
      <span>Home</span>
    </>
  ),

  pos: (
    <>
      <i className="icon-stack-text" />
      <span>PO</span>
    </>
  ),
  asn: (
    <>
      <i className="icon-stack" />
      <span>ASNs</span>
    </>
  ),
  vendor: (
    <>
      <i className="icon-store" />
      <span>Vendors</span>
    </>
  ),
  vendorasn: (
    <>
      <i className="icon-stack" />
      <span>ASNs</span>
    </>
  ),
  carrier: (
    <>
      <i className="icon-truck" />
      <span>Carriers</span>
    </>
  ),
  carton: (
    <>
      <i className="icon-cube" />
      <span>Cartons</span>
    </>
  ),
  report: (
    <>
      <i className="icon-clipboard6" />
      <span>Reports</span>
    </>
  ),
  client: (
    <>
      <i style={{ fontSize: ".73rem" }} className="icon-users2" />
      <span>Clients</span>
    </>
  ),
  user: (
    <>
      <i style={{ fontSize: ".73rem" }} className="icon-users4" />
      <span>Users</span>
    </>
  ),
  logs: (
    <>
      <i className="icon-clipboard6" />
      <span>Logs</span>
    </>
  ),
  tools: (
    <>
      <i className="icon-hammer-wrench" />
      <span>Tools</span>
    </>
  ),
  shipment: (
    <>
      <i className="icon-truck" />
      <span>Shipments</span>
    </>
  ),
  warehouse: (
    <>
      <i className="icon-office" />
      <span>Shipper Warehouse</span>
    </>
  ),
  // email: (
  //   <>
  //     <i className="icon-mail5" />
  //     <span>Emails</span>
  //   </>
  // )
  //COMMENT CODE 3112020-SETTINGS
  // settings: (
  //   <>
  //     <i style={{ fontSize: ".80rem" }} className="icon-hammer-wrench" />
  //     <span>Settings</span>
  //   </>
  // )
};
